import { HttpGet } from "../../../core/Http";
import { HttpBaseResponse } from "../../../core/HttpResponse";
import { UserAuthProps } from "../user.type";

export const getUser = async ({ id }: { id?: number }) => {
  const results = await HttpGet<HttpBaseResponse<UserAuthProps>>({
    url: "/users/profile",
    params: {
      id,
    },
    ignoreFalsy: true
  });
  return results;
};
