import { useMutation, useQuery } from "react-query";
import { getValidateResetPassword, postResetPassword } from "./service";
import { AxiosError } from "axios";
import { HttpBaseResponse } from "../../../core/HttpResponse";
import { useToast } from "../../../context/toast/ToastContext";

export const useGetValidateResetPassword = ({
  id,
  otp,
}: {
  id: number;
  otp: string;
}) => {
  return useQuery({
    queryKey: `resetPassword${otp}`,
    queryFn: () => getValidateResetPassword({ id, otp }),
  });
};

export const usePostResetPassword = () => {
  const toast = useToast();
  return useMutation({
    mutationKey: "resetPassword",
    mutationFn: ({
      id,
      otp,
      password,
    }: {
      id: number;
      otp: string;
      password: string;
    }) => postResetPassword({ id, otp, password }),
    onSuccess: () => {
      toast.show("Berhasil reset password, silahkan login kembali");
    },
    onError: (e: AxiosError<HttpBaseResponse<any>>) => {
      const msg =
        e.response?.data.user_error ||
        "Terjadi kesalahan mohon coba beberapa saat lagi";
      toast.show(msg);
    },
  });
};
