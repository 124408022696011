import { Box, Card, CircularProgress, Grid, Typography } from "@mui/material";
import { useUser } from "../../../context/user/UserContext";
import { FromStringToDate } from "../../../utils/Helper";
import { HistoryImageThumbanil } from "../History.style";
import { useGetHistoryMysteryBox } from "../network/resolver";
import { ENV_LISTS } from "../../../core/EnvTranspiler";

export default function HistoryMysteryBox() {
  const user = useUser();
  const { data: dataRaw, isLoading } = useGetHistoryMysteryBox({
    idUser: user.get().id,
    enabled: user.get().id !== undefined,
  });
  const dataHistory = dataRaw?.data.data;

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <div>
      {dataHistory?.map((item, index) => {
        return (
          <Card key={index} style={{ marginBottom: 10, position: "relative" }}>
            <Box padding={1}>
              <Grid container>
                <Grid item>
                  <HistoryImageThumbanil
                    src={`${ENV_LISTS.API_URL}${item.img}`}
                  />
                </Grid>
                <Grid item>
                  <Box paddingLeft={1.5}>
                    <Typography variant="h6" fontWeight={"bold"}>
                      {item.name}
                    </Typography>
                    <Typography
                      variant="body2"
                      marginTop={1}
                      fontWeight={"bold"}
                      color="#aeaeae"
                    >
                      {FromStringToDate(item.created_at)}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Card>
        );
      })}
    </div>
  );
}
