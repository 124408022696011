import { useQuery } from "react-query";
import { getHistoryMysteryBox, getHistoryTransactions } from "./service";

export const useGetTransactions = ({
  idUser,
  enabled,
}: {
  idUser: number;
  enabled: boolean;
}) => {
  return useQuery({
    queryKey: "historyTransaction",
    queryFn: () => getHistoryTransactions({ idUser }),
    enabled: enabled,
  });
};

export const useGetHistoryMysteryBox = ({
  idUser,
  enabled,
}: {
  idUser: number;
  enabled: boolean;
}) => {
  return useQuery({
    queryKey: `historyMysteryBox`,
    queryFn: () => getHistoryMysteryBox({ idUser }),
    enabled: enabled,
  });
};
