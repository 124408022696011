import { Box, Grid, Modal, Pagination, Typography } from "@mui/material";
import {
  ProductCard,
  ProductCardItem,
  ProductImage,
  ProductImageLarge,
  ProductPaggination,
  ProductPrice,
} from "./ProductList.styles";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import { useGetProductPrize } from "../../network/resolver";
import { useEffect, useState } from "react";
import {
  MainContainerModal,
  MainContainerWrapper,
} from "../../../../styles/Main.styles";
import ProductActions from "../ProductActions/ProductActions";
import { GetProductPrize } from "../../Home.type";
import { ToMoneyFormat } from "../../../../utils/Helper";
import { ENV_LISTS } from "../../../../core/EnvTranspiler";

export default function ProductList() {
  const [page, setPage] = useState(0);
  const { data: productPrizeRaw } = useGetProductPrize({ page });
  const productPrize = productPrizeRaw?.data.data;
  const [showModal, setShowModal] = useState(false);
  const [selectedPrize, setSelectedPrize] = useState<GetProductPrize>();
  const [totalRows, setTotalRows] = useState(0);

  useEffect(() => {
    if (
      productPrizeRaw?.data.totalRows &&
      productPrizeRaw?.data.totalRows > 0
    ) {
      setTotalRows(Math.ceil(productPrizeRaw.data.totalRows / 9));
    }
  }, [productPrizeRaw]);

  function handleSelectProduct(item: GetProductPrize) {
    setSelectedPrize(item);
    setShowModal(true);
  }

  return (
    <div>
      <Grid container spacing={1}>
        {productPrize &&
          productPrize.map((item, index) => {
            return (
              <ProductCardItem key={index} item xs={6} md={4} lg={4}>
                <ProductCard>
                  <ProductImage
                    src={`${ENV_LISTS.API_URL}${item?.img}`}
                    onClick={() => handleSelectProduct(item)}
                  />
                  <Box paddingLeft={1} paddingRight={1}>
                    <Box>
                      <Typography variant="body1">{item.name}</Typography>
                    </Box>
                    <ProductPrice>
                      <MonetizationOnIcon
                        style={{
                          color: "orange",
                          marginRight: 3,
                          fontSize: 20,
                        }}
                      />
                      <Typography fontWeight={"bold"}>
                        {ToMoneyFormat(item.price)}
                      </Typography>
                    </ProductPrice>
                    <ProductActions id={item.id} price={item.price} />
                  </Box>
                </ProductCard>
              </ProductCardItem>
            );
          })}
      </Grid>
      <ProductPaggination>
        <Pagination
          onChange={(e: any, index: any) => setPage(index)}
          count={totalRows}
          variant="outlined"
          shape="rounded"
        />
      </ProductPaggination>
      <Modal open={showModal} onClose={() => setShowModal(false)}>
        <MainContainerModal style={{ width: 400 }}>
          <MainContainerWrapper>
            <ProductImageLarge
              src={`${ENV_LISTS.API_URL}${selectedPrize?.img}`}
            />
            <Typography
              variant="body1"
              marginBottom={1}
              marginTop={1}
              fontWeight="bold"
            >
              {selectedPrize?.name}
            </Typography>
            <ProductPrice style={{ marginBottom: 10 }}>
              <MonetizationOnIcon
                style={{ color: "orange", marginRight: 5, fontSize: 25 }}
              />
              <Typography fontWeight={"bold"} variant="h6">
                {ToMoneyFormat(selectedPrize?.price)}
              </Typography>
            </ProductPrice>
            <ProductActions
              id={selectedPrize?.id}
              price={selectedPrize?.price}
            />
          </MainContainerWrapper>
        </MainContainerModal>
      </Modal>
    </div>
  );
}
