import { Box, Button, CircularProgress, Typography } from "@mui/material";
import { FormWrapper, TextInput } from "../Login.styles";
import { useEffect, useRef, useState } from "react";
import { useAuthenticate, useOtp } from "../network/resolver";
import { countdown } from "../../../utils/Helper";

export default function Otp() {
  const COOLDOWN_OTP = 120;
  const refTimer = useRef<any>(null);
  const {
    mutate: mutateOtp,
    isLoading: loadingOtp,
    isSuccess: successOtp,
  } = useOtp();
  const { mutate: mutateAuth, isLoading: loadingLogin } = useAuthenticate();
  const [otp, setOtp] = useState<string>();
  const [showOtpCooldownTime, setShowOtpCooldownTime] = useState<number>(0);
  const loading = loadingOtp || loadingLogin;

  // Show cooldown otp if, user success sent code
  useEffect(() => {
    if (successOtp && showOtpCooldownTime === 0) {
      setShowOtpCooldownTime(COOLDOWN_OTP);
    }
  }, [successOtp]);

  useEffect(() => {
    if (showOtpCooldownTime > 0 && refTimer.current === null) {
      refTimer.current = setInterval(() => {
        setShowOtpCooldownTime((prev) => prev - 1);
      }, 1000);
    } else if (showOtpCooldownTime <= 0 && refTimer.current) {
      clearInterval(refTimer.current);
      refTimer.current = null;
    }
  }, [showOtpCooldownTime]);

  function handleRequestOtp(type: "email" | "phone") {
    mutateOtp();
  }

  function handleLogin() {
    if (otp) {
      mutateAuth({ otp });
    }
  }

  return (
    <Box>
      <FormWrapper>
        <TextInput
          required={true}
          placeholder="Kode OTP"
          onChange={(e: any) => setOtp(e.target.value)}
        />
        <Box display={"flex"} gap={1} marginBottom={2}>
          {showOtpCooldownTime > 0 ? (
            <Box flexBasis={"100%"}>
              <Typography textAlign={"center"} variant="body2" color={"white"}>
                Anda dapat mengirim kode OTP lagi dalam{" "}
                {countdown(showOtpCooldownTime)}
              </Typography>
            </Box>
          ) : (
            <>
              <Box flexBasis={"50%"}>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => handleRequestOtp("email")}
                >
                  <Typography variant="caption" color={"white"}>
                    Request By Email
                  </Typography>
                </Button>
              </Box>
              <Box flexBasis={"50%"}>
                <Button
                  variant="contained"
                  fullWidth
                  onClick={() => handleRequestOtp("email")}
                >
                  <Typography variant="caption" color={"white"}>
                    Request By Phone
                  </Typography>
                </Button>
              </Box>
            </>
          )}
        </Box>
        <Box>
          <Button
            onClick={handleLogin}
            variant="contained"
            fullWidth
            style={{ backgroundColor: "white", color: "black" }}
          >
            {loading ? <CircularProgress size={20} /> : "Login"}
          </Button>
        </Box>
      </FormWrapper>
    </Box>
  );
}
