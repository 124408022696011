import { useMutation } from "react-query";
import { useToast } from "../../../context/toast/ToastContext";
import { useUser } from "../../../context/user/UserContext";
import { patchPassword, patchProfile } from "./service";
import { AxiosError } from "axios";
import { HttpBaseResponse } from "../../../core/HttpResponse";

export const usePatchProfile = () => {
  const toast = useToast();
  const user = useUser();

  return useMutation({
    mutationKey: "patchProfile",
    mutationFn: ({
      email,
      phone,
      bankAccNo,
      bankAccName,
      username,
    }: {
      email?: string;
      phone?: string;
      bankAccNo?: string | null;
      bankAccName?: string | null;
      username: string;
    }) => patchProfile({ email, phone, bankAccNo, bankAccName, username }),
    onSuccess: (e) => {
      toast.show("Berhasil melakukan update profile");
      user.refresh();
    },
    onError: (e: AxiosError<HttpBaseResponse<any>>) => {
      const msg =
        e.response?.data.user_error ||
        "Terjadi kesalahan mohon coba beberapa saat lagi";
      toast.show(msg);
    },
  });
};

export const usePatchPassword = () => {
  const toast = useToast();

  return useMutation({
    mutationKey: "patchPassword",
    mutationFn: ({
      id,
      oldPassword,
      newPassword,
    }: {
      id: number;
      oldPassword: string;
      newPassword: string;
    }) => patchPassword({ id, oldPassword, newPassword }),
    onSuccess: () => {
      toast.show("Berhasil update password");
    },
    onError: (e: AxiosError<HttpBaseResponse<any>>) => {
      const msg =
        e.response?.data.user_error ||
        "Terjadi kesalahan mohon coba beberapa saat lagi";
      toast.show(msg);
    },
  });
};