import { HttpGet, HttpPost } from "../../../core/Http";
import { HttpBaseResponse } from "../../../core/HttpResponse";
import { VerificationEmail } from "../Varification.type";

export const postValidateEmail = async ({
  id,
  otp,
}: {
  id: number;
  otp: string;
}) => {
  const results = await HttpPost({
    url: "/users/verification/email",
    data: {
      id,
      otp
    },
  });
  return results;
};

export const getValidateEmail = async ({
  id,
  otp,
}: {
  id: number;
  otp: string;
}) => {
  const results = await HttpGet<HttpBaseResponse<VerificationEmail>>({
    url: "/email/changes",
    params: {
      id,
      otp,
    },
  });
  return results;
};
