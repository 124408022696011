import styled from "@emotion/styled";
import { Box } from "@mui/material";

const MainPadding = styled.div`
  padding: 20px;
`;

const MainContainerModal = styled(Box)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-height: 800px;
  overflow-y: scroll;
`;

const MainContainerWrapper = styled.div`
  background-color: white;
  padding: 20px;
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export {
  MainPadding,
  MainContainerModal,
  ButtonContainer,
  MainContainerWrapper,
};
