import { CircularProgress, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useGetValidateEmail, useValidateEmail } from "./network/resolver";

export default function Verification() {
  const navigate = useNavigate();
  const { otp, id } = useParams();
  const idTarget = id ? parseInt(id) : 0;
  const otpTarget = otp || "";
  const { data, isLoading } = useGetValidateEmail({
    id: idTarget,
    otp: otpTarget,
  });
  const dataEmail = data?.data.data;
  const { mutateAsync } = useValidateEmail();
  const [status, setStatus] = useState<null | boolean>(null);

  useEffect(() => {
    handleVerification();
  }, [dataEmail]);

  function handleVerification() {
    if (dataEmail) {
      if (Object.keys(dataEmail).length === 0) {
        navigate("/login", {
          replace: true,
        });
        return;
      }
      mutateAsync({
        id: dataEmail.id_user,
        otp: dataEmail.otp
      }).then(res => {
        setStatus(true)
      }).catch(err => {
        setStatus(false)
      })
    }
  }

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <Box
      display="flex"
      height="100vh"
      width="100vw"
      justifyContent="center"
      alignItems="center"
      padding={1}
    >
      {status === null && <CircularProgress />}
      {status && (
        <Typography variant="h5">Email Anda Berhasil di verifikasi</Typography>
      )}
      {status === false && (
        <Typography variant="h5" textAlign="center">
          Tidak dapat melakukan verifikasi email untuk saat ini
        </Typography>
      )}
    </Box>
  );
}
