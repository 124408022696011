import styled from "styled-components";
import { ENV_LISTS } from "../../core/EnvTranspiler";

interface LogoProps {
  img?: string;
}

const ContainerLogo = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  margin-bottom: 10px;
`;

const ContentLogo = styled.img`
  object-fit: contain;
  object-position: center;
  width: 100%;
  height: 100%;
`;

export default function Logo({ img }: LogoProps) {
  if (!img) {
    return null;
  }

  return (
    <ContainerLogo>
      <ContentLogo src={`${ENV_LISTS.API_URL}${img}`} />
    </ContainerLogo>
  );
}
