import { ENV_LISTS } from "../../../../core/EnvTranspiler";
import { GetDesignMedia } from "../../Home.type";
import { MediaContainer, MediaContent } from "./MiddleMedia.styles";

interface MiddleMediaProps {
  media?: GetDesignMedia;
}

export default function MiddleMedia({ media }: MiddleMediaProps) {
  const source =
    media?.type === 1
      ? `${ENV_LISTS.API_URL}${media.src}`
      : media?.src;

  return (
    <MediaContainer>
      <MediaContent src={source} />
    </MediaContainer>
  );
}
