import React, { useState } from "react";
import { ENV_LISTS } from "../../core/EnvTranspiler";
import { IAuthToken } from "./AuthContext.type";

interface AuthContextProps {
  get: () => IAuthToken;
  set: ({
    token,
    shouldStoreOnStorage,
  }: {
    token: string;
    shouldStoreOnStorage: boolean;
  }) => void;
}

const AuthContextDefault: AuthContextProps = {
  get: () => {
    return {} as IAuthToken;
  },
  set({ token, shouldStoreOnStorage }) {},
};

export const AuthStateContext = React.createContext(AuthContextDefault);

const AuthContextFC: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [tokenAuth, setTokenAuth] = useState<IAuthToken>({} as IAuthToken);

  function set({
    token,
    shouldStoreOnStorage,
  }: {
    token: string;
    shouldStoreOnStorage: boolean;
  }) {
    setTokenAuth((prev) => {
      prev.token = token;
      return prev;
    });
    if (shouldStoreOnStorage) {
      const temp = JSON.stringify(token)
      localStorage.setItem(ENV_LISTS.KEY_TOKEN, temp);
    }
  }

  function get() {
    return tokenAuth;
  }

  return (
    <AuthStateContext.Provider value={{ get, set }}>
      {children}
    </AuthStateContext.Provider>
  );
};

export const useAuthToken = () => React.useContext(AuthStateContext);

export default AuthContextFC;
