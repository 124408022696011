import { Grid } from "@mui/material";
import { useNavigate } from "react-router";
import { Cover } from "../../home/Home.styles";

export default function Play() {
  const navigate = useNavigate();

  function handleOnClick(path: string) {
    navigate(path);
  }
  
  return (
    <div>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Cover onClick={() => handleOnClick("/mystery-box")}>
            Mysterybox
          </Cover>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Cover>Comming Soon</Cover>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Cover>Comming Soon</Cover>
        </Grid>
        <Grid item xs={12} sm={6} md={6} lg={6}>
          <Cover>Comming Soon</Cover>
        </Grid>
      </Grid>
    </div>
  );
}
