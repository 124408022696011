import { CircularProgress, Snackbar } from "@mui/material";
import React, { useState } from "react";
import { SpinnerPosition } from "./SpinnerContext.styles";

interface SpinnerContextProps {
  show: (open: boolean) => void;
}

const spinnerContextDefault: SpinnerContextProps = {
  show: () => {},
};

export const SpinnerStateContext = React.createContext<SpinnerContextProps>(
  spinnerContextDefault
);

const SpinnerContextFC: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [visible, setVisible] = useState(false);

  function show(visible: boolean) {
    setVisible(visible);
  }

  return (
    <SpinnerStateContext.Provider value={{ show }}>
      {children}
      {visible && (
        <SpinnerPosition>
          <CircularProgress size={30} />
        </SpinnerPosition>
      )}
    </SpinnerStateContext.Provider>
  );
};

export default SpinnerContextFC;
