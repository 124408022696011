import styled from "styled-components";

export const InformationCard = styled.div`
  text-align: center;
  border-radius: 5px;
  border: 1px solid #aeaeae;
  padding: 5px;
  height: 20px;
`

export const InformationCardContent = styled(InformationCard)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`