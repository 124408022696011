import { Grid } from "@mui/material";
import Chest from "../../../components/chest/Chest";
import Logo from "../../../components/logo/Logo";
import { useUser } from "../../../context/user/UserContext";
import Information from "../../home/components/Information/Information";
import { useGetDesign } from "../../home/network/resolver";
import { useGetMysteryBoxPrize } from "../network/resolvers";

export default function MysteryBox() {
  const user = useUser();
  const { data: mysteryboxDataRaw, isLoading } = useGetMysteryBoxPrize({
    idMemberTier: user.get().id_member_tier,
    enabled: user.get().id_member_tier !== undefined,
  });
  const dataMysterybox = mysteryboxDataRaw?.data.data;
  const { data: designdataRaw } = useGetDesign();
  const desingData = designdataRaw?.data.data.setting;

  if (isLoading) {
    return null;
  }

  return (
    <div>
      <Logo img={desingData?.logo} />
      <Information />
      <Grid container spacing={2}>
        {/* {dataMysterybox?.map((item, index) => {
          return (
            <Grid key={index} item xs={4} sm={4} md={4} lg={4}>
              <Chest />
            </Grid>
          );
        })} */}
        <Grid item xs={4} sm={4} md={4} lg={4}>
          <Chest />
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={4}>
          <Chest />
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={4}>
          <Chest />
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={4}>
          <Chest />
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={4}>
          <Chest />
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={4}>
          <Chest />
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={4}>
          <Chest />
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={4}>
          <Chest />
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={4}>
          <Chest />
        </Grid>
      </Grid>
    </div>
  );
}
