import styled from "styled-components";

const Container = styled.div`
  background-color: black;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;
const MediumText = styled.div`
  font-size: 20px;
  color: white;
  font-weight: bold;
`;
const FormWrapper = styled.form`
  margin-top: 20px;
  width: 400px;
  display: flex;
  flex-direction: column;
`;

const TextInput = styled.input`
  background-color: transparent;
  border: 1px solid #dedede;
  border-radius: 5px;
  padding: 10px;
  color: white;
  margin-bottom: 15px;
`;

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

const ButtonBody = styled.div`
  display: flex;
  height: 40px;
  flex-basis: 48%;
  overflow: hidden;
  cursor: pointer;
`;

const ButtonRegister = styled(ButtonBody)`
  background-color: white;
  border-radius: 7px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 14px;
`

const ButtonLogin = styled(ButtonBody)`
  display: flex;
  flex-direction: column;
  color: white;
  justify-content: center;
  & > div {
    font-size: 10px;
    color: #aeaeae;
    margin-top: 3px;
  }
`

const ForgotText = styled.div`
  font-size: 14px;
  color: #aeaeae;
  margin-top: 25px;
  display: flex;
  justify-content: center;
  width: 100%;
  cursor: pointer;
`

export {
  Container,
  MediumText,
  FormWrapper,
  TextInput,
  ButtonWrapper,
  ButtonBody,
  ButtonRegister,
  ButtonLogin,
  ForgotText
};
