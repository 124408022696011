import { HttpGet, HttpPost } from "../../../core/Http";
import { HttpBaseResponse } from "../../../core/HttpResponse";
import { ICustomerService, ILatestTrade, LoginAuthProps } from "../types/login";

interface postRegisterProps {
  name: string;
  username: string;
  password: string;
  email: string;
}

interface IPreLogin {
  token: string;
}

export const postLogin = async ({ otp }: { otp: string }) => {
  const result = await HttpPost<HttpBaseResponse<LoginAuthProps>>({
    url: "/auth",
    data: {
      otp,
    },
  });
  return result;
};

export const postPreLogin = async ({
  username,
  password,
}: {
  username: string;
  password: string;
}) => {
  const result = await HttpPost<HttpBaseResponse<IPreLogin>>({
    url: "/auth/prelogin",
    data: {
      username,
      password,
    },
  });
  return result;
};

export const postRegister = async ({
  name,
  username,
  password,
  email,
}: postRegisterProps) => {
  const result = await HttpPost<HttpBaseResponse<LoginAuthProps>>({
    url: "/users",
    data: {
      name,
      username,
      password,
      email,
    },
  });
  return result;
};

export const postOtpRequest = async ({ token }: { token: string }) => {
  const result = await HttpPost({
    url: "/auth/otp",
    headers: {
      authorization: token,
    },
  });
  return result;
};

export const getLatestTrade = async () => {
  const result = await HttpGet<HttpBaseResponse<ILatestTrade[]>>({
    url: "/transaction/public",
  });
  return result
};

export const getCustomerService = async () => {
  const result = await HttpGet<HttpBaseResponse<ICustomerService[]>>({
    url: "/customer-service",
  });
  return result;
};

export const postForgotPassword = async ({email}:{email:string}) => {
  const result = await HttpPost({
    url: '/auth/forgot',
    data: {
      email
    }
  })
  return result
}