import styled from "styled-components";

export const ToastContainer = styled.div<{ visible: boolean }>`
  transition: all 0.2s ease-out;
  z-index: 99999;
  position: fixed;
  visibility: ${(props) => (props.visible ? "visible" : "hidden")};
  top: ${(props) => (props.visible ? "40%" : "80%")};
  left: 29%;
  background-color: #fefefe;
  padding: 4px;
  border-radius: 5px;
`;
