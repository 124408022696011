import { Card, Box, Grid, Typography, CircularProgress } from "@mui/material";
import { useUser } from "../../context/user/UserContext";
import { ToMoneyFormat } from "../../utils/Helper";
import { HistoryImageThumbanil } from "../history/History.style";
import { useGetAddWishlist } from "../home/network/resolver";
import { ENV_LISTS } from "../../core/EnvTranspiler";

export default function Wishlist() {
  const user = useUser();
  const { data: dataRawWishlist, isLoading } = useGetAddWishlist({
    idUser: user.get().id,
    enabled: user.get().id !== undefined,
  });
  const dataWishList = dataRawWishlist?.data.data;

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <Box padding={1}>
      {dataWishList?.map((item, index) => {
        return (
          <Card key={index} style={{ marginBottom: 10, position: "relative" }}>
            <Box padding={1}>
              <Grid container>
                <Grid item>
                  <HistoryImageThumbanil
                    src={`${ENV_LISTS.API_URL}${item.img}`}
                  />
                </Grid>
                <Grid item>
                  <Box paddingLeft={1.5}>
                    <Typography variant="h6" fontWeight={"bold"}>
                      {item.name}
                    </Typography>
                    <Typography
                      variant="body1"
                      marginTop={1}
                      fontWeight={"bold"}
                      color="#aeaeae"
                    >
                      {ToMoneyFormat(item.price)}
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </Card>
        );
      })}
    </Box>
  );
}
