import { createBrowserRouter } from "react-router-dom";
import History from "../features/history/History";
import Home from "../features/home/Home";
import Login from "../features/login/Auth";
import MysteryBox from "../features/mystery_box";
import Play from "../features/play";
import Profile from "../features/profile/Profile";
import Ticket from "../features/ticket/Ticket";
import Verification from "../features/verification/Verification";
import Wishlist from "../features/wishlist/Wishlist";
import Layout from "./Layout";
import PrivateRoute from "./PrivateRoute";
import ResetPassword from "../features/reset_password/ResetPassword";

const router = createBrowserRouter([
  {
    index: true,
    path: "/login",
    element: <Login />,
  },
  {
    path: "/verification/:otp/:id",
    element: <Verification />,
  },
  {
    path: "/reset/:id/:otp",
    element: <ResetPassword/>,
  },
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        index: true,
        element: (
          <PrivateRoute>
            <Home />
          </PrivateRoute>
        ),
      },
      {
        path: "/play",
        element: (
          <PrivateRoute>
            <Play />
          </PrivateRoute>
        ),
      },
      {
        path: "/mystery-box",
        element: (
          <PrivateRoute>
            <MysteryBox />
          </PrivateRoute>
        ),
      },
      {
        path: "/history",
        element: (
          <PrivateRoute>
            <History />
          </PrivateRoute>
        ),
      },
      {
        path: "/ticket",
        element: (
          <PrivateRoute>
            <Ticket />
          </PrivateRoute>
        ),
      },
      {
        path: "/wishlist",
        element: (
          <PrivateRoute>
            <Wishlist />
          </PrivateRoute>
        ),
      },
      {
        path: "/profile",
        element: (
          <PrivateRoute>
            <Profile />
          </PrivateRoute>
        ),
      },
    ],
  },
]);

export default router;
