import { Box, Snackbar, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import React, { useEffect, useRef, useState } from "react";
import { ToastContainer } from "./ToastContext.styles";

interface ToastContextProps {
  show: (message: string, type?: "toast" | "dialog") => void;
}

const toastContextDefault: ToastContextProps = {
  show: () => {},
};

export const ToastStateContext =
  React.createContext<ToastContextProps>(toastContextDefault);

const ToastContextFC: React.FC<React.PropsWithChildren> = ({ children }) => {
  const TIMER_CLOSE = 2
  const refTimerDialog = useRef<any>(null)
  const [visible, setVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [typeOfToast, setTypeOfToast] = useState<"toast" | "dialog">("toast");
  const [ctrTimer, setCtrTimer] = useState(0)

  useEffect(() => {
    if (ctrTimer >= TIMER_CLOSE) {
      setVisible(false)
      setCtrTimer(0)
      clearInterval(refTimerDialog.current)
      refTimerDialog.current = null
    }
  },[ctrTimer])

  function show(message: string, type?: "toast" | "dialog") {
    if (visible) {
      return
    }
    // Create timer when dialog
    if (type === 'dialog') {
      refTimerDialog.current = setInterval(() => {
        setCtrTimer(prev => prev + 1)
      },1000)
    }
    setTypeOfToast(type || "toast");
    setMessage(message);
    setVisible(true);
  }

  function handleClose() {
    setVisible(false);
  }

  return (
    <Box position={"relative"}>
      <ToastStateContext.Provider value={{ show }}>
        {children}
        {typeOfToast === "toast" && (
          <Snackbar
            open={visible}
            autoHideDuration={1500}
            onClose={handleClose}
            message={message}
            anchorOrigin={{
              horizontal: "right",
              vertical: "bottom",
            }}
          />
        )}
        <ToastContainer visible={visible && typeOfToast === "dialog"}>
          <Box display={"flex"} justifyContent={"flex-end"}>
            <CloseIcon onClick={handleClose} />
          </Box>
          <Box
            display={"flex"}
            justifyContent={"center"}
            alignItems={"center"}
            height={100}
            width={400}
          >
            <Typography variant="body1">{message}</Typography>
          </Box>
        </ToastContainer>
      </ToastStateContext.Provider>
    </Box>
  );
};

export const useToast = () => React.useContext(ToastStateContext);

export default ToastContextFC;
