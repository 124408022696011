import styled from "@emotion/styled";

export const Cover = styled.div`
  border-radius: 5px;
  border: 1px solid #dedede;
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 150px;
  cursor: pointer;
`