import { useMutation, useQuery } from "react-query";
import { playMysteryBox } from "./service";
import { AxiosError } from "axios";
import { HttpBaseResponse } from "../../../core/HttpResponse";
import { useToast } from "../../../context/toast/ToastContext";

export const usePlayMysteryBox = () => {
  const toast = useToast()
  
  return useMutation({
    mutationKey: "playMysteryBox",
    mutationFn: ({
      idMemberTier,
      idUser,
    }: {
      idMemberTier: number;
      idUser: number;
    }) => playMysteryBox({ idMemberTier, idUser }),
    onError: (e: AxiosError<HttpBaseResponse<any>>) => {
      const msg =
        e.response?.data.user_error ||
        "Terjadi kesalahan mohon coba beberapa saat lagi";
      toast.show(msg);
    },
  });
};
