import { Box, Tabs, Tab } from "@mui/material";
import { useState } from "react";
import HistoryMysteryBox from "./components/HistoryMysteryBox";
import HistoryTransaction from "./components/HistoryTransaction";

export default function History() {
  const [selectedTab, setSelectedTab] = useState(0);

  return (
    <div>
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs value={selectedTab} onChange={(e, v) => setSelectedTab(v)}>
          <Tab label="Mystery Box" id="0" />
          <Tab label="Penukaran" id="1" />
        </Tabs>
      </Box>
      <Box padding={1}>
        {selectedTab === 0 && <HistoryMysteryBox />}
        {selectedTab === 1 && <HistoryTransaction />}
      </Box>
    </div>
  );
}
