import { useQuery } from "react-query";
import { getUser } from "./service";

export const useGetUser = (enabled: boolean) => {
  return useQuery({
    queryKey: `userProfile`,
    enabled: enabled,
    queryFn: () => getUser({}),
  });
};
