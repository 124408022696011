import React, { useEffect, useState } from "react";
import { useGetUser } from "./network/resolver";
import { UserAuthProps } from "./user.type";
import { ENV_LISTS } from "../../core/EnvTranspiler";

interface UserContextProps {
  get: () => UserAuthProps;
  refresh: () => void;
}

const userContextDefault: UserContextProps = {
  get: () => {
    return {} as UserAuthProps;
  },
  refresh: () => {},
};

export const UserStateContext = React.createContext(userContextDefault);

const UserContextFC: React.FC<React.PropsWithChildren> = ({ children }) => {
  const [shoulGetUserInfo, setShouldGetUserInfo] = useState(false);
  const { data, refetch } = useGetUser(shoulGetUserInfo);
  const dataUser = data?.data.data;

  useEffect(() => {
    if (!data) {
      const temp = localStorage.getItem(ENV_LISTS.KEY_TOKEN);
      if (temp) {
        setShouldGetUserInfo(true);
      }
    }
  }, []);

  function get() {
    return dataUser || ({} as UserAuthProps);
  }

  function refresh() {
    refetch();
  }

  return (
    <UserStateContext.Provider value={{ get, refresh }}>
      {children}
    </UserStateContext.Provider>
  );
};

export const useUser = () => React.useContext(UserStateContext);

export default UserContextFC;
