import { useNavigate } from "react-router";
import Logo from "../../components/logo/Logo";
import RunningText from "../../components/running_text/RunningText";
import Information from "./components/Information/Information";
import MiddleMedia from "./components/MiddleMedia/MiddleMedia";
import ProductList from "./components/ProductList/ProductList";
import { useGetDesign } from "./network/resolver";
import Play from "../play";
import { Box } from "@mui/material";

export default function Home() {
  const navigate = useNavigate();
  const { data: designdataRaw } = useGetDesign();
  const desingData = designdataRaw?.data.data.setting;

  return (
    <div>
      <Logo img={desingData?.logo} />
      <Information />
      <RunningText text={desingData?.runningText} />
      <MiddleMedia media={desingData?.media.cover} />
      <Box marginTop={2}>
        <Play />
      </Box>
      <Box marginTop={2}>
        <ProductList />
      </Box>
    </div>
  );
}
