import styled, { keyframes } from "styled-components";

interface RunningTextProps {
  text?: string;
}

const runningAnimation = keyframes`
  0% {left: -100%};
  100% {left: 0%}
`;

const ContainerRunningText = styled.div`
  height: 30px;
  width: 100%;
  overflow: hidden;
  position: relative;
  border-top: 1px solid #aeaeae;
  border-bottom: 1px solid #aeaeae;
  margin-bottom: 10px;
  display: flex;
  
  & > div {
    display: block;
    width: 200%;
    height: 30px;
    position: absolute;
    overflow: hidden;
    animation: ${runningAnimation} 7s linear infinite;
  }
  & > div > span {
    float: left;
    width: 50%;
    display: flex;
    align-items: center;
    height: 100%;
  }
`;

export default function RunningText({ text }: RunningTextProps) {
  return (
    <ContainerRunningText>
      <div>
        <span>{text}</span>
        <span>{text}</span>
      </div>
    </ContainerRunningText>
  );
}
