import { Box, Card, Modal, Typography } from "@mui/material";
import { useUser } from "../../../../../context/user/UserContext";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import {
  MainContainerModal,
  MainContainerWrapper,
} from "../../../../../styles/Main.styles";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { FromStringToDate } from "../../../../../utils/Helper";
import { CancelOutlined } from "@mui/icons-material";

interface TicketList {
  visible: boolean;
  onClose(): void;
}

export default function TicketList({ visible, onClose }: TicketList) {
  const user = useUser();

  return (
    <Modal open={visible}>
      <MainContainerModal>
        <MainContainerWrapper>
          <Box height="100vh" width="100vw">
            <Box
              bgcolor="#fefefe"
              borderBottom="1px solid #aeaeae"
              alignItems="center"
              display="flex"
              padding="10px"
            >
              <div onClick={() => onClose()} style={{ display: "flex" }}>
                <ArrowBackIcon />
                <Typography marginLeft={1}>Kembali</Typography>
              </div>
            </Box>
            <Box marginTop="10px" padding="10px">
              {user.get().ticket?.total > 0 ? (
                user.get().ticket.list.map((item, index) => {
                  return (
                    <Card style={{ marginBottom: 10 }} key={index}>
                      <Box padding="10px" display="flex" alignItems="center">
                        <ConfirmationNumberIcon style={{ color: "green" }} />
                        <Typography marginLeft={1} fontWeight="bold">
                          {item.amount}
                        </Typography>
                        <Typography marginLeft={1}>
                          ticket akan kadaluarsa pada
                        </Typography>
                        <Typography marginLeft={1} fontWeight="bold">
                          {FromStringToDate(item.valid_until)}
                        </Typography>
                      </Box>
                    </Card>
                  );
                })
              ) : (
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  flexDirection="column"
                >
                  <CancelOutlined fontSize="large" />
                  <Typography marginTop={1}>
                    Anda tidak memiliki ticket saat ini.
                  </Typography>
                </Box>
              )}
            </Box>
          </Box>
        </MainContainerWrapper>
      </MainContainerModal>
    </Modal>
  );
}
