import styled from "@emotion/styled";

export const PrizeOverviewContainer = styled.div`
  border-radius: 5px;
  overflow: hidden;
  width: 100%;
  height: 300px;
  margin-top: 10px;
`;

export const PrizeOverview = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

export const PrizeLoader = styled.div`
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
`;
