import { useMutation, useQuery } from "react-query";
import { getValidateEmail, postValidateEmail } from "./service";

export const useValidateEmail = () => {
  return useMutation({
    mutationKey: "validateEmail",
    mutationFn: ({ id, otp }: { id: number; otp: string }) =>
      postValidateEmail({ id, otp }),
  });
};

export const useGetValidateEmail = ({
  id,
  otp,
}: {
  id: number;
  otp: string;
}) => {
  return useQuery({
    queryKey: `emailVerification${id}`,
    queryFn: () => getValidateEmail({ id, otp }),
  });
};
