import { AxiosError } from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import { useToast } from "../../../context/toast/ToastContext";
import { HttpBaseResponse } from "../../../core/HttpResponse";
import {
  getDesign,
  getProductPrize,
  getWishlist,
  postAddWishlist,
  postTransactionTradePrize,
  putWishlist,
} from "./service";

export const useGetDesign = () => {
  return useQuery({
    queryKey: "design",
    queryFn: () => getDesign(),
  });
};

export const useGetProductPrize = ({page}:{page:number}) => {
  return useQuery({
    queryKey: `productPrize${page}`,
    queryFn: () => getProductPrize({page}),
  });
};

export const usePostTransactionTradePrize = () => {
  const toast = useToast();
  return useMutation({
    mutationKey: "postTransactionTradePrize",
    mutationFn: ({ id, idUser }: { id: number; idUser: number }) =>
      postTransactionTradePrize({ id, idUser }),
    onSuccess: (e) => {
      toast.show("Berhasil melakukan penukaran point", 'dialog');
    },
    onError: (e: AxiosError<HttpBaseResponse<any>>) => {
      const msg =
        e.response?.data.user_error ||
        "Terjadi kesalahan mohon coba beberapa saat lagi";
      toast.show(msg);
    },
  });
};

export const usePostAddWishlist = () => {
  const toast = useToast();
  const query = useQueryClient();
  return useMutation({
    mutationKey: "postAddWishlist",
    mutationFn: ({ id, idUser }: { id: number; idUser: number }) =>
      postAddWishlist({ id, idUser }),
    onSuccess: (e) => {
      toast.show("Berhasil menambahkan wishlist");
      query.invalidateQueries();
    },
    onError: (e: AxiosError<HttpBaseResponse<any>>) => {
      const msg =
        e.response?.data.user_error ||
        "Terjadi kesalahan mohon coba beberapa saat lagi";
      toast.show(msg);
    },
  });
};

export const usePutWishlist = () => {
  const toast = useToast();
  const query = useQueryClient();
  return useMutation({
    mutationKey: "putAddWishlist",
    mutationFn: ({ id }: { id: number }) => putWishlist({ id }),
    onSuccess: (e) => {
      toast.show("Berhasil update wishlist");
      query.invalidateQueries();
    },
    onError: (e: AxiosError<HttpBaseResponse<any>>) => {
      const msg =
        e.response?.data.user_error ||
        "Terjadi kesalahan mohon coba beberapa saat lagi";
      toast.show(msg);
    },
  });
};

export const useGetAddWishlist = ({
  idUser,
  enabled,
}: {
  idUser: number;
  enabled: boolean;
}) => {
  return useQuery({
    queryKey: `wishlist${idUser}`,
    queryFn: () => getWishlist({ idUser }),
    enabled,
  });
};
