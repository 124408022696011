import { useEffect } from "react";
import { useNavigate } from "react-router";
import { ENV_LISTS } from "../core/EnvTranspiler";

interface PrivateRouteProps {
  children: JSX.Element;
}

export default function PrivateRoute({ children }: PrivateRouteProps) {
  const navigate = useNavigate();
  const keyToken = localStorage.getItem(ENV_LISTS.KEY_TOKEN)

  useEffect(() => {
    if (!keyToken) {
      navigate("/login");
    }
  },[keyToken])

  return <div>{children}</div>;
}
