import { Grid } from "@mui/material";
import styled from "styled-components";

export const ProductContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  gap: 15px;
  margin-top: 7px;
`;

export const ProductCardItem = styled(Grid)`

`

export const ProductCard = styled.div`
  padding: 0px;
  height: 300px;
  border-radius: 5px;
  overflow: hidden;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
`;

export const ProductImage = styled.img`
  height: 150px;
  width: 100%;
  object-fit: cover;
`;

export const ProductName = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  & > div {
    font-size: 14px;
    width: 90%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
`;

export const ProductPrice = styled.div`
  display: flex;
  align-items: center;
  margin-top: 5px;
`;

export const ProductAction = styled.div`
  display: flex;
  border: 1px solid #aeaeae;
  border-radius: 5px;
  margin-left: 5px;
  margin-right: 5px;
  background-color: #4a4940cb;
  color: white;
  cursor: pointer;
  margin-bottom: 7px;
`;

export const ProductPaggination = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const ProductImageLarge = styled.img`
  height: 300px;
  width: 100%;
  object-fit: contain;
  border: 1px solid #aeaeae;
  border-radius: 5px;
  overflow: hidden;
`;

export const CardProductTagContainer = styled.div`
  display: flex;
  gap: 5px;
  border-radius: 5px;
  border: 2px solid #dedede;
  padding: 3px;
  align-items: center;
  margin-bottom: 8px;
  cursor: pointer;
`
