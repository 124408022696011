import axios from "axios";
import { useAuth } from "../hooks/user/UserHooks";
import { ENV_LISTS } from "./EnvTranspiler";

interface IRequest {
  url: string;
  data?: any;
  headers?: any;
  params?: any;
  useFormMultiPart?: boolean;
  showToast?: boolean;
  ignoreFalsy?: boolean;
}

/**
 * Create Axios Instance with default
 */
export const apiInstance = axios.create({
  timeout: 30000,
  baseURL: ENV_LISTS.API_URL,
});

// request interceptors
apiInstance.interceptors.request.use(async (config) => {
  // custom request interceptors
  const auth = useAuth();
  if (auth) {
    config.headers = {
      Authorization: `Bearer ${auth}`,
    };
  }
  return config;
});

// response interceptors
apiInstance.interceptors.response.use(
  async (res) => {
    //await responseInterceptors(res);
    return res;
  },
  async (err: any) => {
    //await responseErrorInterceptors(err);
    return Promise.reject(err);
  }
);

export const HttpGet = <T = any>({
  url,
  params,
  useFormMultiPart,
}: IRequest) => {
  let urlTarget = url;
  // Build url to params
  if (params) {
    urlTarget += "?";
    Object.keys(params).forEach((item) => {
      if (params[item]) {
        urlTarget += `${item}=${params[item]}&`;
      }
    });
    // remove last &
    urlTarget = urlTarget.substring(0, urlTarget.length - 1);
  }
  return apiInstance.get<T>(urlTarget);
};

export const HttpPost = <T = any>({
  url,
  data,
  useFormMultiPart,
  ignoreFalsy = false,
  headers,
}: IRequest) => {
  if (headers) {
    apiInstance.interceptors.request.use(async (config) => {
      if (headers?.authorization) {
        config.headers = {
          Authorization: `Bearer ${headers.authorization}`,
        };
      }
      return config;
    });
  }
  if (useFormMultiPart) {
    const form = new FormData();
    Object.keys(data).forEach((item) => {
      if (!ignoreFalsy) {
        if (data[item] !== undefined && data[item] !== null) {
          form.append(item, data[item]);
        }
      } else {
        form.append(item, data[item]);
      }
    });
    return apiInstance.post<T>(url, form);
  }
  return apiInstance.post<T>(url, data);
};

export const HttpPut = <T = any>({
  url,
  data,
  useFormMultiPart,
  ignoreFalsy = false,
}: IRequest) => {
  if (useFormMultiPart) {
    const form = new FormData();
    Object.keys(data).forEach((item) => {
      if (!ignoreFalsy) {
        if (data[item] !== undefined && data[item] !== null) {
          if (Array.isArray(data[item])) {
            data[item].forEach((itemForm: any, indexForm: number) => {
              form.append(`${item}[${indexForm}]`, itemForm);
            });
          } else {
            form.append(item, data[item]);
          }
        }
      } else {
        form.append(item, data[item]);
      }
    });
    return apiInstance.put<T>(url, form);
  }
  return apiInstance.put<T>(url, data);
};
