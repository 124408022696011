import { HttpPost } from "../../../core/Http";
import { HttpBaseResponse } from "../../../core/HttpResponse";
import { PlayMysteryBox } from "../Chest.type";

export const playMysteryBox = ({
  idMemberTier,
  idUser,
}: {
  idMemberTier: number;
  idUser: number;
}) => {
  const result = HttpPost<HttpBaseResponse<PlayMysteryBox>>({
    url: "/mysterybox/play/start",
    data: {
      idUser,
      idMemberTier
    },
  });
  return result
};
