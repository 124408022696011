import { Box, CircularProgress } from "@mui/material";
import {
  FormWrapper,
  TextInput,
  ButtonWrapper,
  ButtonRegister,
  ForgotText,
} from "../Login.styles";
import { useEffect, useState } from "react";
import {
  useGetCustomerService,
  usePostForgotPassword,
  usePreLogin,
} from "../network/resolver";
import { useToast } from "../../../context/toast/ToastContext";
import { useAuthToken } from "../../../context/auth/AuthContext";
import { useLocation } from "react-router";
import { getUrlSearchParams } from "../../../utils/Helper";
import Otp from "./Otp";

export default function Login() {
  const toast = useToast();
  const userToken = useAuthToken();
  const locataion = useLocation();
  const { data: dataRawCustomerService } = useGetCustomerService();
  const dataCustomerService = dataRawCustomerService?.data.data;
  const { mutate: mutatePreLogin } = usePreLogin();
  const { mutate: mutateForgotPassword, isLoading: loadingForgot } =
    usePostForgotPassword();
  const [username, setUsername] = useState();
  const [password, setPassword] = useState();
  const [email, setEmail] = useState();
  const [showOtpForm, setShowOtpForm] = useState(false);
  const [showForgot, setShowForgot] = useState(false);

  useEffect(() => {
    if (locataion.search) {
      const token = userToken.get();
      const params = getUrlSearchParams(window.location.href);
      if (params.otpRequest && token?.token) {
        setShowOtpForm(true);
      }
    }
  }, [locataion]);

  function handleSubmit() {
    if (!username || !password) {
      toast.show("Mohon lengkapi informasi login");
      return;
    }
    mutatePreLogin({
      username,
      password,
    });
  }

  function handleLiveChat() {
    if (dataCustomerService) {
      const target = dataCustomerService.filter(
        (item) => item.name === "livechat"
      );
      window.open(target[0].url);
    }
  }

  function handleForgotPassword() {
    if (!email) {
      toast.show("Mohon lengkapi field");
      return;
    }
    mutateForgotPassword({
      email,
    });
  }

  return (
    <Box>
      {showOtpForm ? (
        <Otp />
      ) : (
        <FormWrapper>
          {showForgot ? (
            <TextInput
              required={true}
              placeholder="Email"
              onChange={(e: any) => setEmail(e.target.value)}
            />
          ) : (
            <>
              <TextInput
                required={true}
                placeholder="Username"
                onChange={(e: any) => setUsername(e.target.value)}
              />
              <TextInput
                required
                placeholder="Password"
                type="password"
                onChange={(e: any) => setPassword(e.target.value)}
              />
            </>
          )}
          <ButtonWrapper>
            <ButtonRegister onClick={handleLiveChat}>Live Chat</ButtonRegister>
            {showForgot && (
              <ButtonRegister onClick={handleForgotPassword}>
                {loadingForgot ? <CircularProgress size={20} /> : "Reset"}
              </ButtonRegister>
            )}
            {!showForgot && (
              <ButtonRegister onClick={handleSubmit}>Login</ButtonRegister>
            )}
          </ButtonWrapper>
          <Box onClick={() => setShowForgot(true)}>
            <ForgotText>Forgot your Password?</ForgotText>
          </Box>
        </FormWrapper>
      )}
    </Box>
  );
}
