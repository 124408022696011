import { HttpPut } from "../../../core/Http";

export const patchProfile = async ({
  email,
  phone,
  bankAccNo,
  bankAccName,
  username,
}: {
  email?: string;
  phone?: string;
  bankAccNo?: string | null;
  bankAccName?: string | null;
  username: string;
}) => {
  const results = await HttpPut({
    url: "/users",
    data: {
      email,
      phone,
      bank_acc_no: bankAccNo,
      bank_acc_name: bankAccName,
      username,
    },
  });
  return results
};


export const patchPassword = async ({
  id,
  oldPassword,
  newPassword,
}: {
  id: number;
  oldPassword: string;
  newPassword: string;
}) => {
  const result = await HttpPut({
    url: "/users/password",
    data: {
      id,
      oldPassword,
      newPassword
    }
  });
  return result
};

