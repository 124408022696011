import { useQuery } from "react-query";
import { getMysteryBoxPrize } from "./service";

export const useGetMysteryBoxPrize = ({
  idMemberTier,
  enabled
}: {
  idMemberTier: number;
  enabled: boolean
}) => {
  return useQuery({
    queryKey: `mysteryboxprize${idMemberTier}`,
    queryFn: () => getMysteryBoxPrize({ idMemberTier }),
    enabled
  });
};
