import { HttpGet } from "../../../core/Http";
import { HttpBaseResponse } from "../../../core/HttpResponse";
import { HistoryMysteryBoxType, HistoryTransaction } from "../History.type";

export const getHistoryTransactions = async ({
  idUser,
}: {
  idUser: number;
}) => {
  const results = await HttpGet<HttpBaseResponse<HistoryTransaction[]>>({
    url: "/transaction",
    params: {
      idUser
    },
  });
  return results
};

export const getHistoryMysteryBox = async ({
  idUser,
}: {
  idUser: number;
}) => {
  const results = await HttpGet<HttpBaseResponse<HistoryMysteryBoxType[]>>({
    url: "/mysterybox/prize/history",
    params: {
      idUser
    },
  });
  return results
};

