import styled from "@emotion/styled";

export const HistoryImageThumbanil = styled.img`
  width: 100px;
  min-height: 100%;
  object-fit: cover;
  object-position: center;
  border-radius: 5px;
`;

export const HistoryStatus = styled.div`
  position: absolute;
  top: 0%;
  right: 0%;
  padding: 7px;
  & > div {
    border-radius: 5px;
    padding: 3px;
    color: white;
    background-color: red;
    font-size: 12px;
  }
`;
