import React from "react";
import { Container, MediumText } from "./Login.styles";
import Logo from "../../components/logo/Logo";
import { useGetDesign } from "../home/network/resolver";
import { Box } from "@mui/material";
import Login from "./components/Login";
import LatestTrade from "./components/LatestTrade";

export default function Auth() {
  const { data: dataDesignRaw } = useGetDesign();
  const dataDesign = dataDesignRaw?.data.data.setting;

  return (
    <Container>
      <Logo img={dataDesign?.logo} />
      <MediumText>LOGIN</MediumText>
      <Login />
      <Box
        marginTop={2}
        color={"white"}
        border={"1px solid white"}
        padding={1}
        borderRadius={1}
        maxWidth={500}
        minWidth={400}
      >
        <LatestTrade />
      </Box>
    </Container>
  );
}
