import styled from "@emotion/styled";

export const LayoutContainer = styled.div`
  align-self: center;
  display: flex;
  justify-content: center;
  padding-bottom: 70px;
`

export const LayoutWrapper = styled.div`
  width: 100%;
  max-width: 768px;
  padding-top: 10px;
`

export const LayoutBottomNav = styled.div`
  position: fixed;
  bottom: 0%;
  border-top: 2px solid #ededed;
  border-left: 2px solid #ededed;
  border-right: 2px solid #ededed;
  max-width: 768px;
  height: 40px;
  width: 100%;
  display: flex;
  justify-content: space-around;
  padding: 10px;
  margin-left: -10px;
  background-color: #ededed;
`

export const LayoutBottomNavItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
`