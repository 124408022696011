import styled from "styled-components";

const SpinnerPosition = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  background-color: rgba(255, 255, 255, 0.5);
  padding: 20px;
  border-radius: 7px;
  transform: translate(-50%, -50%);
  z-index: 99999;
`;

export { SpinnerPosition };
