import { HttpGet, HttpPost } from "../../../core/Http";
import { HttpBaseResponse } from "../../../core/HttpResponse";
import { IResetPassword } from "../ResetPassword.type";

export const getValidateResetPassword = async ({
  id,
  otp,
}: {
  id: number;
  otp: string;
}) => {
  const result = await HttpGet<HttpBaseResponse<IResetPassword[]>>({
    url: "/users",
    params: {
      id,
      otp,
    },
  });
  return result;
};

export const postResetPassword = async ({
  id,
  otp,
  password,
}: {
  id: number;
  otp: string;
  password: string;
}) => {
  const result = await HttpPost({
    url: '/auth/reset',
    data: {
      id,
      otp,
      password
    }
  })
  return result
};
