import styled from "@emotion/styled";

export const MediaContainer = styled.div`
  width: 100%;
  height: 100%;
`

export const MediaContent = styled.img`
  object-fit: contain;
  object-position: center;
  width: 100%;
  height: 100%;
`