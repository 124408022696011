import {
  Button,
  Card,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useRef, useState } from "react";
import { useUser } from "../../context/user/UserContext";
import "./Chest.style.css";
import {
  PrizeLoader,
  PrizeOverview,
  PrizeOverviewContainer,
} from "./Chest.styles";
import { WinningPrize } from "./Chest.type";
import { usePlayMysteryBox } from "./network/resolver";
import { ENV_LISTS } from "../../core/EnvTranspiler";
import { useToast } from "../../context/toast/ToastContext";

export default function Chest() {
  const refContainer = useRef<any>();
  const ref = useRef<any>();
  const refTimer = useRef<any>();
  const user = useUser();
  const [_, setIsPlay] = useState(false);
  const { mutate: mutatePlay, isLoading } = usePlayMysteryBox();
  const [showDialog, setShowDialog] = useState(false);
  const [winningPrize, setWinningPrize] = useState<WinningPrize>();
  const toast = useToast()

  function handleShowDialog() {
    if (user.get().ticket.total <= 0) {
      toast.show('Anda tidak memiliki tiket untuk bermain')
      setShowDialog(false);
      return
    }
    setShowDialog(true)
  }

  function openChest() {
    setShowDialog(false);
    setIsPlay(true);
    mutatePlay(
      {
        idMemberTier: user.get().id_member_tier,
        idUser: user.get().id,
      },
      {
        onSuccess: (e) => {
          // Set winning prize
          setWinningPrize(e.data.data.winningPrize);
          setIsPlay(false);
          // Start Chest Animation
          let ctr = 2;
          refTimer.current = setInterval(() => {
            if (ctr > 12) {
              return;
            }
            ref.current.src = `/Chest ${ctr}.png`;
            ctr++;
            if (ctr >= 12) {
              if (refContainer.current) {
                refContainer.current.classList.add("two");
                // Destory Timer
                clearInterval(refTimer.current);
              }
            }
          }, 50);
          // Refresh User Information
          user.refresh()
        },
      }
    );
  }

  function closeModal() {
    if (refContainer.current) {
      refContainer.current.classList.remove("two");
      // reset chest
      ref.current.src = `/Chest 1.png`;
    }
  }

  return (
    <div
      onClick={closeModal}
      style={{ display: "flex", justifyContent: "center" }}
    >
      <div onClick={handleShowDialog}>
        <img
          src={`/Chest 1.png`}
          ref={ref}
          style={{ width: "100%" }}
        />
      </div>
      <div ref={refContainer} id="modal-container">
        <div className="modal-background">
          <div className="modal">
            <Typography variant="body1">Anda Mendapatkan</Typography>
            <PrizeOverviewContainer>
              <PrizeOverview
                src={`${ENV_LISTS.API_URL}${winningPrize?.img}`}
              ></PrizeOverview>
            </PrizeOverviewContainer>
            <Typography fontWeight={"bold"} marginTop={1}>
              {winningPrize?.name}
            </Typography>
          </div>
        </div>
      </div>
      <Dialog
        open={showDialog}
        onClose={() => setShowDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Anda akan membuka kotak mystery box!
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Membuka Mystery Box akan menggunkan 1 tiket anda, aksi ini tidak
            dapat dibatalkan
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setShowDialog(false)}>Cancel</Button>
          <Button onClick={openChest} autoFocus>
            Buka
          </Button>
        </DialogActions>
      </Dialog>
      {isLoading && (
        <PrizeLoader>
          <Card>
            <Box padding={2}>
              <CircularProgress size={25} />
            </Box>
          </Card>
        </PrizeLoader>
      )}
    </div>
  );
}
