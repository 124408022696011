import { Box, Button, Card, TextField, Typography } from "@mui/material";
import { LoadingButton } from "../../components/button/Button";
import {
  useGetValidateResetPassword,
  usePostResetPassword,
} from "./network/resolver";
import { useNavigate, useParams } from "react-router";
import { useEffect, useState } from "react";
import { useToast } from "../../context/toast/ToastContext";

export default function ResetPassword() {
  const toast = useToast();
  const navigate = useNavigate();
  const { id, otp } = useParams();
  const { data: dataRawValidate, isLoading: loadingValidate } =
    useGetValidateResetPassword({
      id: parseInt(id || "0"),
      otp: otp || "",
    });
  const dataValidate = dataRawValidate?.data.data[0];
  const { mutate: mutateResetPassword, isLoading: loadingResetPassword } =
    usePostResetPassword();
  const [password, setPassword] = useState("");
  const [rePassword, setRePassword] = useState("");

  useEffect(() => {
    if (!loadingValidate && !dataValidate?.id) {
      // Incorect otp
      //navigate("/login");
    }
  }, [loadingValidate, dataRawValidate]);

  function handleSubmit() {
    if (!password || !rePassword) {
      toast.show("Mohon lengkapi field");
      return;
    } else if (password !== rePassword) {
      toast.show("Password tidak sama");
      return;
    }
    if (otp && id) {
      mutateResetPassword({
        password,
        id: parseInt(id),
        otp,
      });
    }
  }

  if (loadingValidate) {
    return null;
  }

  return (
    <Box
      height={"100vh"}
      display={"flex"}
      justifyContent={"center"}
      alignItems={"center"}
      color={"white"}
    >
      <Card>
        <Box padding={2} width={350}>
          <Box marginBottom={2}>
            <Typography variant="body1">Reset Password</Typography>
          </Box>
          <TextField
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            fullWidth
            placeholder="Password Baru"
            label="Password Baru"
            style={{ color: "white" }}
            margin="normal"
            type="password"
          />
          <TextField
            onChange={(e) => setRePassword(e.target.value)}
            value={rePassword}
            fullWidth
            placeholder="Confirm Password Baru"
            label="Confirm Password Baru"
            style={{ color: "white" }}
            margin="normal"
            type="password"
          />
          <Box
            marginTop={2}
            display={"flex"}
            justifyContent={"flex-end"}
            gap={1}
          >
            <Button
              variant="contained"
              color="info"
              onClick={() => navigate("/login")}
            >
              Login
            </Button>
            <LoadingButton
              text="Submit"
              onClick={handleSubmit}
              isLoading={loadingResetPassword}
            />
          </Box>
        </Box>
      </Card>
    </Box>
  );
}
