import { Box, Typography } from "@mui/material";
import { useGetLatestTrade } from "../network/resolver";

export default function LatestTrade() {
  const { data: dataLatestTradeRaw } = useGetLatestTrade();
  const dataLatestTrade = dataLatestTradeRaw?.data.data;

  return (
    <Box
      display={"flex"}
      gap={1}
      flexDirection={"column"}
      maxHeight={180}
      overflow={"scroll"}
    >
      {dataLatestTrade?.map((item, index) => {
        return (
          <Box display={"flex"} key={index}>
            <Typography fontWeight={600} marginRight={1}>
              {item.nameUser}
            </Typography>
            <Typography marginRight={1}> menukar</Typography>
            <Typography fontWeight={"bold"}>{item.name}</Typography>
          </Box>
        );
      })}
    </Box>
  );
}
