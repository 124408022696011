import { History, Home, Person, PlayArrow, Star } from "@mui/icons-material";
import { Outlet, useNavigate } from "react-router-dom";
import {
  LayoutBottomNav,
  LayoutBottomNavItem,
  LayoutContainer,
  LayoutWrapper,
} from "./Layout.styles";
import PrivateRoute from "./PrivateRoute";

export default function Layout() {
  const navigate = useNavigate();
  const NAVIGATION_PATH = [
    {
      text: "Home",
      path: "/",
      icon: (
        <PrivateRoute>
          <Home />
        </PrivateRoute>
      ),
    },
    {
      text: "History",
      path: "/history",
      icon: <History />,
    },
    {
      text: "Profile",
      path: "/profile",
      icon: <Person />,
    },
    {
      text: "Wishlist",
      path: "/wishlist",
      icon: <Star />,
    },
  ];

  function handleOnClick(path: string) {
    navigate(path);
  }

  return (
    <LayoutContainer>
      <LayoutWrapper>
        <Outlet />
        <LayoutBottomNav>
          {NAVIGATION_PATH.map((item, index) => {
            return (
              <LayoutBottomNavItem
                key={index}
                onClick={() => handleOnClick(item.path)}
              >
                {item.icon}
              </LayoutBottomNavItem>
            );
          })}
        </LayoutBottomNav>
      </LayoutWrapper>
    </LayoutContainer>
  );
}
