import { HttpGet, HttpPost, HttpPut } from "../../../core/Http";
import { HttpBaseResponse } from "../../../core/HttpResponse";
import {
  GetDesign,
  GetProductPrize,
  GetWishlist,
  PostTransactions,
  PostWishlist,
} from "../Home.type";

export const getDesign = async () => {
  const results = await HttpGet<HttpBaseResponse<GetDesign>>({
    url: "/design",
  });
  return results;
};

export const getProductPrize = async ({page}:{page:number}) => {
  const results = await HttpGet<HttpBaseResponse<GetProductPrize[]>>({
    url: "/prize",
    params: {
      limit: 9,
      page
    }
  });
  return results;
};

export const postTransactionTradePrize = async ({
  id,
  idUser,
}: {
  id: number;
  idUser: number;
}) => {
  const results = await HttpPost<HttpBaseResponse<PostTransactions>>({
    url: "/transaction",
    data: {
      id,
      id_user: idUser,
    },
  });
  return results;
};

export const postAddWishlist = async ({
  id,
  idUser,
}: {
  id: number;
  idUser: number;
}) => {
  const results = await HttpPost<HttpBaseResponse<PostWishlist>>({
    url: "/wishlist",
    data: {
      idPrize: id,
      idUser: idUser,
    },
  });
  return results;
};

export const putWishlist = async ({ id }: { id: number }) => {
  const results = await HttpPut<HttpBaseResponse<PostWishlist>>({
    url: "/wishlist",
    data: {
      id: id,
    },
  });
  return results;
};

export const getWishlist = async ({ idUser }: { idUser: number }) => {
  const results = await HttpGet<HttpBaseResponse<GetWishlist[]>>({
    url: "/wishlist",
    params: {
      idUser,
    },
  });
  return results;
};
