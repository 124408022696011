import { ENV_LISTS } from "../../core/EnvTranspiler"
import { LoginAuthProps } from "../../features/login/types/login"

export const useAuth = () => {
  function getUserInformation() {
    const tempUser = localStorage.getItem(ENV_LISTS.KEY_TOKEN)
    if (tempUser) {
      const dataUser:LoginAuthProps = JSON.parse(tempUser)
      return dataUser
    }
    return null
  }

  return getUserInformation()
}