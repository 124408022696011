import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from "@mui/material";
import { CardProductTagContainer } from "../ProductList/ProductList.styles";
import { useEffect, useState } from "react";
import {
  useGetAddWishlist,
  usePostAddWishlist,
  usePostTransactionTradePrize,
  usePutWishlist,
} from "../../network/resolver";
import { useUser } from "../../../../context/user/UserContext";
import { useToast } from "../../../../context/toast/ToastContext";
import { GetWishlist } from "../../Home.type";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import FavoriteIcon from "@mui/icons-material/Favorite";

interface ProductActionsProps {
  id?: number;
  price?: number;
}

export default function ProductActions({ id, price }: ProductActionsProps) {
  const user = useUser();
  const toast = useToast();
  const [showDialog, setShowDialog] = useState(false);
  const { mutate: mutateWishlist } = usePostAddWishlist();
  const { mutate: mutateTransaction, isLoading } =
    usePostTransactionTradePrize();
  const { mutate: mutateUpdateWishlist } = usePutWishlist();
  const { data: dataRawWishlist } = useGetAddWishlist({
    idUser: user.get().id,
    enabled: user.get().id !== undefined,
  });
  const dataWishlist = dataRawWishlist?.data.data;
  const [wishlistItem, setwishlistItem] = useState<GetWishlist | null>(null);
  const [isListed, setIslisted] = useState(false);

  useEffect(() => {
    if (dataWishlist) {
      const temp = dataWishlist.find((item) => item.idPrize === id);
      if (temp) {
        setwishlistItem(temp);
        setIslisted(true);
      }
    }
  }, [dataWishlist]);

  function handleSubmit() {
    if (id && price) {
      setShowDialog(false)
      if (user.get().point >= price) {
        mutateTransaction(
          {
            id,
            idUser: user.get().id,
          },
          {
            onSuccess: () => {
              setShowDialog(false);
              user.refresh();
            },
          }
        );
      } else {
        toast.show("Point tidak cukup", 'dialog');
      }
    }
  }

  function handleAddWishlist() {
    if (id) {
      setIslisted((prev) => !prev);
      if (wishlistItem) {
        mutateUpdateWishlist({ id: wishlistItem.id });
      } else {
        mutateWishlist({ id: id, idUser: user.get().id });
      }
    }
  }

  return (
    <div>
      <Box marginTop={1}>
        <CardProductTagContainer onClick={() => setShowDialog(true)}>
          <ShoppingCartIcon color={"success"} />
          <Typography variant="body2" fontWeight={"bold"}>
            Tukar Point
          </Typography>
        </CardProductTagContainer>
        <CardProductTagContainer onClick={handleAddWishlist}>
          <FavoriteIcon color={isListed ? "error" : "action"} />
          <Typography variant="body2" fontWeight={"bold"}>
            Wishlist
          </Typography>
        </CardProductTagContainer>
      </Box>
      <Dialog
        open={showDialog}
        onClose={() => setShowDialog(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          Transaksi Penukaran Point
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Apakah anda yakin ingin melakukan penukaran point dengan item ini ?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button color="error" onClick={() => setShowDialog(false)}>
            Cancel
          </Button>
          <Button variant="outlined" onClick={handleSubmit}>
            {isLoading ? <CircularProgress size={20} /> : "Ok"}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
