import { Grid, Typography } from "@mui/material";
import { InformationCard, InformationCardContent } from "./Information.style";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import ConfirmationNumberIcon from "@mui/icons-material/ConfirmationNumber";
import { useUser } from "../../../../context/user/UserContext";
import { ToMoneyFormat } from "../../../../utils/Helper";
import { useState } from "react";
import TicketList from "./components/TicketList";
import { PlayArrow } from "@mui/icons-material";
import { useNavigate } from "react-router";

export default function Information() {
  const navigate = useNavigate()
  const user = useUser();
  const [showModal, setShowModal] = useState(false);

  function logout() {
    localStorage.clear();
    window.location.reload();
  }

  return (
    <Grid container>
      <Grid container alignItems={"center"} padding={1} paddingBottom={0}>
        <Grid item xs={2}>
          <Typography variant="body1">Home</Typography>
        </Grid>
        <Grid item xs={7}>
          <InformationCard style={{ marginRight: 10 }}>
            <span style={{ fontWeight: "bold" }}>Welcome</span>{" "}
            {user.get().name}
          </InformationCard>
        </Grid>
        <Grid item xs={3}>
          <InformationCard onClick={logout}>Logout</InformationCard>
        </Grid>
      </Grid>
      <Grid container alignItems={"center"} padding={1}>
        <Grid item xs={2}>
          <Typography variant="body1">CS 24/7</Typography>
        </Grid>
        <Grid item xs={3}>
          <InformationCard style={{ marginRight: 10 }}>
            Livechat
          </InformationCard>
        </Grid>
        <Grid item>
          <InformationCardContent
            style={{ marginRight: 10 }}
            onClick={() => setShowModal(true)}
          >
            <ConfirmationNumberIcon
              style={{ marginRight: 3, color: "green" }}
            />
            <Typography fontWeight={"bold"}>
              {user.get().ticket?.total}
            </Typography>
          </InformationCardContent>
        </Grid>
        <Grid item>
          <InformationCardContent>
            <MonetizationOnIcon style={{ marginRight: 3, color: "orange" }} />
            <Typography fontWeight={"bold"}>
              {ToMoneyFormat(user.get().point)}
            </Typography>
          </InformationCardContent>
        </Grid>
        <Grid item marginLeft={1} onClick={() => navigate('/')}>
          <InformationCardContent>
            <PlayArrow />
            <Typography>PLAY</Typography>
          </InformationCardContent>
        </Grid>
      </Grid>
      <TicketList visible={showModal} onClose={() => setShowModal(false)} />
    </Grid>
  );
}
