import { useMutation, useQuery } from "react-query";
import {
  getCustomerService,
  getLatestTrade,
  postForgotPassword,
  postLogin,
  postOtpRequest,
  postPreLogin,
  postRegister,
} from "./service";
import { AxiosError } from "axios";
import { HttpBaseResponse } from "../../../core/HttpResponse";
import { useToast } from "../../../context/toast/ToastContext";
import { useAuthToken } from "../../../context/auth/AuthContext";
import { useNavigate } from "react-router";

export const useAuthenticate = () => {
  const toast = useToast();
  const authToken = useAuthToken();
  const navigate = useNavigate();

  return useMutation({
    mutationKey: "login",
    mutationFn: ({ otp }: { otp: string }) => postLogin({ otp }),
    onSuccess: (e) => {
      authToken.set({ token: e.data.data.token, shouldStoreOnStorage: true });
      navigate("/");
      window.location.reload();
    },
    onError: (e: AxiosError<HttpBaseResponse<any>>) => {
      const msg =
        e.response?.data.user_error ||
        "Terjadi kesalahan mohon coba beberapa saat lagi";
      toast.show(msg);
    },
  });
};

export const usePreLogin = () => {
  const toast = useToast();
  const authToken = useAuthToken();
  const navigate = useNavigate();

  return useMutation({
    mutationKey: "preLogin",
    mutationFn: ({
      username,
      password,
    }: {
      username: string;
      password: string;
    }) => postPreLogin({ username, password }),
    onSuccess: (e) => {
      authToken.set({ token: e.data.data.token, shouldStoreOnStorage: false });
      navigate("/login?otpRequest=true", {
        replace: true,
      });
    },
    onError: (e: AxiosError<HttpBaseResponse<any>>) => {
      const msg =
        e.response?.data.user_error ||
        "Terjadi kesalahan mohon coba beberapa saat lagi";
      toast.show(msg);
    },
  });
};

export const useRegister = () => {
  return useMutation(postRegister);
};

export const useOtp = () => {
  const toast = useToast();
  const authToken = useAuthToken();

  return useMutation({
    mutationKey: "requestOtp",
    mutationFn: () => postOtpRequest({ token: authToken.get().token }),
    onSuccess: (e) => {
      toast.show("Kode OTP telah dikirim");
    },
    onError: (e: AxiosError<HttpBaseResponse<any>>) => {
      const msg =
        e.response?.data.user_error ||
        "Terjadi kesalahan mohon coba beberapa saat lagi";
      toast.show(msg);
    },
  });
};

export const useGetLatestTrade = () => {
  return useQuery({
    queryKey: `latesTrade`,
    queryFn: () => getLatestTrade(),
  });
};

export const useGetCustomerService = () => {
  return useQuery({
    queryKey: "customerService",
    queryFn: () => getCustomerService(),
  });
};

export const usePostForgotPassword = () => {
  const toast = useToast();
  return useMutation({
    mutationKey: "forgotPassword",
    mutationFn: ({ email }: { email: string }) => postForgotPassword({ email }),
    onSuccess: (e) => {
      toast.show("Kode OTP telah dikirim");
    },
    onError: (e: AxiosError<HttpBaseResponse<any>>) => {
      const msg =
        e.response?.data.user_error ||
        "Terjadi kesalahan mohon coba beberapa saat lagi";
      toast.show(msg);
    },
  });
};
