import React from "react";
import { QueryClientProvider } from "react-query";
import { RouterProvider } from "react-router";
import SpinnerContextFC from "./context/spinner/SpinnerContext";
import ToastContextFC from "./context/toast/ToastContext";
import UserContextFC from "./context/user/UserContext";
import router from "./router/Route";
import queryClient from "./utils/queryClient";
import AuthContextFC from "./context/auth/AuthContext";

function App() {
  return (
    <React.Fragment>
      <QueryClientProvider client={queryClient}>
        <AuthContextFC>
          <ToastContextFC>
            <UserContextFC>
              <SpinnerContextFC>
                <RouterProvider router={router} />
              </SpinnerContextFC>
            </UserContextFC>
          </ToastContextFC>
        </AuthContextFC>
      </QueryClientProvider>
    </React.Fragment>
  );
}

export default App;
