import { KeyToken } from "./Constant";

export const FromStringToDate = (dateParam: string) => {
  let temp = new Date(dateParam);
  return `${temp.getDate()}/${temp.getMonth() + 1}/${temp.getFullYear()}`;
};

export const FromStringToDateTime = (dateParam: string) => {
  if (!dateParam) {
    return "";
  }
  let temp = new Date(dateParam);
  return `${temp.getDate()}/${
    temp.getMonth() + 1
  }/${temp.getFullYear()}  ${temp.getHours()}:${temp.getMinutes()}:${temp.getMilliseconds()}`;
};

export const CreditToString = (credit: string) => {
  let temp = credit.split(".")[0];
  temp = temp.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  if (temp[0] === "-") {
    temp = temp.substr(1, temp.length);
    return `-Rp ${temp}`;
  }
  return `+Rp ${temp}`;
};

export const ToMoneyFormat = (credit: string | number | undefined) => {
  if (!credit) {
    return 0;
  }
  const temp = credit.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  return temp;
};

// export const AddToLog = async (deskripsi: string) => {
//   const HttpLog = new ServiceLog()
//   const tempJson = localStorage.getItem(KeyToken)
//   if (tempJson) {
//     const id = JSON.parse(tempJson).id
//     const a = await HttpLog.AddLog({
//       id_user: id,
//       deskripsi
//     })
//   }
// }

export const MaskingText = (text: string) => {
  if (!text) {
    return text;
  }
  return `***${text.substring(5)}`;
};

export const getUrlSearchParams = (url: string) => {
  const searchParams = new URLSearchParams(new URL(url).search);
  const params: any = {};

  for (const [key, value] of searchParams) {
    params[key] = value;
  }
  return params;
};

export function countdown(seconds: number) {
  let minutes = Math.floor(seconds / 60);
  let remainingSeconds = seconds % 60;
  let countdownString = `${minutes} : ${remainingSeconds}`;
  return countdownString;
}
