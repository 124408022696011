import { HttpGet } from "../../../core/Http";
import { HttpBaseResponse } from "../../../core/HttpResponse";
import { GetMysteryBoxPrize } from "../Mysterybox.type";

export const getMysteryBoxPrize = async ({
  idMemberTier,
}: {
  idMemberTier: number;
}) => {
  const results = await HttpGet<HttpBaseResponse<GetMysteryBoxPrize[]>>({
    url: "/mysterybox",
    params: {
      idMemberTier,
    },
  });
  return results;
};
