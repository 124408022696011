import {
  Box,
  Button,
  Card,
  CircularProgress,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import { useEffect, useState } from "react";
import { useUser } from "../../context/user/UserContext";
import { usePatchProfile } from "./network/resolver";
import Keamanan from "./components/Keamanan";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}

export default function Profile() {
  const user = useUser();
  const { mutate, isLoading: loadingFetch } = usePatchProfile();
  const [email, setEmail] = useState<string>();
  const [telp, setTelp] = useState<string>();
  const [bankAccName, setBankAccName] = useState<string | null>();
  const [bankAccNo, setBankAccNo] = useState<string | null>();
  const [isLoading, setIsLoading] = useState(true);
  const [value, setValue] = useState(0);

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        style={{ visibility: value !== index ? "hidden" : "visible" }}
      >
        <Box sx={{ p: 2 }}>{children}</Box>
      </div>
    );
  }

  useEffect(() => {
    if (user.get().id) {
      if (user.get().email) {
        setEmail(user.get().email);
      }
      if (user.get().phone) {
        setTelp(user.get().phone);
      }
      if (user.get().bank_acc_name) {
        setBankAccName(user.get().bank_acc_name);
      }
      if (user.get().bank_acc_no) {
        setBankAccNo(user.get().bank_acc_no);
      }
      setIsLoading(false);
    }
  }, [user]);

  function handleSubmit() {
    mutate({
      email,
      phone: telp,
      bankAccName,
      bankAccNo,
      username: user.get().username,
    });
  }

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <Card>
      <Box sx={{ borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={(e, value) => setValue(value)}
          aria-label="basic tabs example"
        >
          <Tab label="Data User" value={0} />
          <Tab label="Keamanan" value={1} />
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        <Box display="flex" flexDirection="column" padding={1}>
          <TextField
            placeholder="Username"
            label="Username"
            margin="normal"
            value={user.get().username}
            disabled
          />
          <TextField
            placeholder="Nama"
            label="Nama"
            margin="normal"
            value={user.get().name}
            disabled
          />
          <TextField
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Email"
            label="Email"
            margin="normal"
            value={email}
            type="email"
          />
          <TextField
            onChange={(e) => setTelp(e.target.value)}
            placeholder="No Telp"
            label="No Telp"
            margin="normal"
            value={telp}
          />
          <TextField
            onChange={(e) => setBankAccName(e.target.value)}
            placeholder="Nama Rekening Bank"
            label="Nama Rekening Bank"
            margin="normal"
            value={bankAccName}
            disabled={bankAccNo !== null}
          />
          <TextField
            onChange={(e) => setBankAccNo(e.target.value)}
            placeholder="No Rekening Bank"
            label="No Rekening Bank"
            margin="normal"
            value={bankAccNo}
            disabled={bankAccNo !== null}
          />
          <Box display="flex" justifyContent="flex-end" marginTop={1}>
            <Button variant="contained" onClick={handleSubmit}>
              {loadingFetch ? (
                <CircularProgress
                  style={{ width: 20, height: 20, color: "white" }}
                />
              ) : (
                "Update"
              )}
            </Button>
          </Box>
        </Box>
      </TabPanel>
      <Box padding={2} visibility={value === 1 ? "visible" : "hidden"}>
        <Keamanan id={user.get().id} />
      </Box>
    </Card>
  );
}
