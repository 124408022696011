import { Card, Box, Grid, Typography } from "@mui/material";
import { useUser } from "../../../context/user/UserContext";
import { FromStringToDate, ToMoneyFormat } from "../../../utils/Helper";
import { ProductPrice } from "../../home/components/ProductList/ProductList.styles";
import { HistoryImageThumbanil, HistoryStatus } from "../History.style";
import { useGetTransactions } from "../network/resolver";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import { HISTORY_STATUS } from "../../../constant/Constant";
import { ENV_LISTS } from "../../../core/EnvTranspiler";

export default function HistoryTransaction() {
  const user = useUser();
  const { data: dataHistoryRaw, isLoading } = useGetTransactions({
    idUser: user.get().id,
    enabled: user.get().id !== undefined,
  });
  const dataHistory = dataHistoryRaw?.data.data;

  function getHistoryStatus(status: number) {
    const result = HISTORY_STATUS.find((item) => {
      if (item.code === status) {
        return true;
      }
    });
    return result;
  }
  
  return (
    <div>
      {dataHistory &&
        dataHistory?.map((item, index) => {
          return (
            <Card
              key={index}
              style={{ marginBottom: 10, position: "relative" }}
            >
              <Box padding={1}>
                <Grid container>
                  <Grid item>
                    <HistoryImageThumbanil
                      src={`${ENV_LISTS.API_URL}${item.img}`}
                    />
                  </Grid>
                  <Grid item>
                    <Box paddingLeft={1.5}>
                      <Typography variant="h6" fontWeight={"bold"}>
                        {item.name}
                      </Typography>
                      <Typography
                        variant="body2"
                        marginTop={1}
                        fontWeight={"bold"}
                        color="#aeaeae"
                      >
                        {FromStringToDate(item.createdAt)}
                      </Typography>
                      <ProductPrice style={{ marginBottom: 10 }}>
                        <MonetizationOnIcon
                          style={{
                            color: "orange",
                            marginRight: 5,
                            fontSize: 20,
                          }}
                        />
                        <Typography variant="h6">
                          {ToMoneyFormat(item.price)}
                        </Typography>
                      </ProductPrice>
                    </Box>
                  </Grid>
                </Grid>
                <HistoryStatus>
                  <div
                    style={{
                      backgroundColor: getHistoryStatus(item.status)?.color,
                    }}
                  >
                    {getHistoryStatus(item.status)?.text}
                  </div>
                </HistoryStatus>
              </Box>
            </Card>
          );
        })}
    </div>
  );
}
